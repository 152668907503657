import Order from "store/models/Order";
import OrderEntryActions from "modules/actions/order-entry-actions";
export default {
  name: "OrderList",
  components: {},
  data() {
    return {
      type: "",
      showDialog: false,
      isLoading: false,
      initialLoading: false,
      isDataLoading: false,
      selected: [],
      actions: new OrderEntryActions(),
      changeLog: {},
      enum_status: `[ PatientReportTransmitted, BillingStatementGenerated, BillingStatementSent, BillingStatementPaid]`,
      orders: [],
      limitData: [
        "internalId",
        this.buildSubQuery("sample", ["sampleId"]),
        this.buildSubQuery("payorAccount", ["payorName"]),
        "dateSampleTaken",
        "billingAccessionStatus",
        "billedAmount",
        "netsuiteInvoice",
        "billingNetsuiteStatus",
        "orderEntrySaved",
        "balanceDue",
        "priceDate",
        this.buildSubQuery("test", ["label"]),
        "status",
        "isDeleted",
        "totalCount",
        this.buildSubQuery("trf", ["testRequisitionFormId"]),
      ],
      filter: "",
      columns: [
        {
          name: "internalId",
          align: "left",
          hasAction: true,
          text: "Order ID",
          field: "internalId",
          sortable: true,
        },
        {
          name: "sampleId",
          align: "left",
          text: "Accession ID",
          field: "accessionId",
          sortable: true,
          hasAction: true,
        },
        {
          name: "payor",
          align: "left",
          text: "Order Payor Name",
          field: "payor",
        },
        {
          name: "dateSampleTaken",
          align: "left",
          text: "Date of Service",
          field: "dateOfService",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "billingAccessionStatus",
          align: "left",
          text: "Accession Status",
          field: "billingAccessionStatus",
          sortable: false,
        },
        {
          name: "billedAmount",
          align: "left",
          text: "Total Amount",
          field: "billedAmount",
          sortable: false,
        },
        {
          name: "rejectedReason",
          align: "left",
          text: "Rejected Reason",
          field: "rejectedReason",
          sortable: false,
        },
        {
          name: "paidAmount",
          align: "left",
          text: "Paid Amount",
          field: "paidAmount",
          sortable: false,
        },
        {
          name: "netsuiteInvoice",
          align: "left",
          text: "Netsuite Invoice Number",
          field: "netsuiteInvoice",
          sortable: false,
        },
        {
          name: "billingNetsuiteStatus",
          align: "left",
          text: "Netsuite Invoice Status",
          field: "billingNetsuiteStatus",
          sortable: false,
        },
        {
          name: "remarkCodes",
          align: "left",
          text: "Remark Codes",
          field: "remarkCodes",
          sortable: false,
        },
      ],
      // note:
      // option values should be fetched on api before passing as props value to search filter component
      // add multiselect: true for multiselect field functionality
      searchFilter: {
        columns: 4,
        model: Order,
        fields: [
          {
            label: "Payor Name",
            name: "payorName",
            belongsTo: "payorAccount",
            type: "text",
            maxlength: 100,
          },
          {
            label: "Order ID",
            name: "internalId",
            type: "text",
          },
          {
            label: "Invoice Number",
            name: "netsuiteInvoice",
            type: "text",
          },
          {
            label: "Amount",
            name: "billedAmount",
            type: "amount",
          },
          {
            label: "Accession Status",
            name: "billingAccessionStatus",
            type: "select",
            selectOptions: [
              {
                label: "Billing Review",
                value: "BillingReview",
              },
              {
                label: "Unpriceable",
                value: "Unpriceable",
              },
              {
                label: "Unbillable",
                value: "Unbillable",
              },
              {
                label: "Priced",
                value: "Priced",
              },
              {
                label: "Billed",
                value: "Billed",
              },
              {
                label: "Priced With Errors",
                value: "PricedWithErrors",
              },
              {
                label: "Posting",
                value: "Posting",
              },
              {
                label: "Zero Balance",
                value: "ZeroBalance",
              },
              {
                label: "Credit Balance",
                value: "CreditBalance",
              },
            ],

            isEnum: true,
            singleSelect: true,
          },
          {
            label: "Billiing Netsuite Status",
            name: "billingNetsuiteStatus",
            type: "text",
          },

          {
            label: "Creation Date",
            name: "created",
            type: "date",
            asUtc: true,
          },
        ],
      },
      totalCount: 0,
    };
  },
  computed: {
    data() {
      if (this.orders.length > 0) {
        const data = [];
        this.orders.forEach((item) => {
          const {
            id,
            internalId,
            trf,
            test,
            payorAccount,
            status,
            dateSampleTaken,
            billingAccessionStatus,
            billedAmount,
            sample,
            isDeleted,
            orderEntrySaved,
            billingNetsuiteStatus,
            balanceDue,
            priceDate,
            netsuiteInvoice,
          } = item;
          data.push({
            data: item,
            id,
            accessionId: sample ? sample.sampleId : "--",
            internalId,
            trf,
            billingAccessionStatus,
            orderEntrySaved,
            billingNetsuiteStatus,
            billedAmount,
            balanceDue,
            priceDate,
            netsuiteInvoice,
            test: test ? test.label : "--",
            payor: payorAccount ? payorAccount.payorName : "--",
            status,
            dateOfService: dateSampleTaken || "",
            isDeleted: isDeleted || false,
            actionLabel: {
              internalId: {
                action: this.toOrderEntry,
              },
              sampleId: {
                action: this.toAccession,
                disable: !orderEntrySaved,
              },
            },
          });
        });
        return data;
      } else return [];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        this.initialLoading = true;

        await this.fetch();
      } catch (error) {
        this.showNotifyMessage({
          message: "A problem has occured while fetching data.",
          type: "danger",
        });
      } finally {
        this.initialLoading = false;
      }
    });
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        // console.log(e);
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.orders = result;
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } selected of ${this.data.length}`;
    },
    async toOrderEntry(id) {
      this.$router.push(`/orders/order-entry/${id}`);
    },
    async toAccession(id) {
      this.$router.push(`/orders/accession-details/${id}`);
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
};
